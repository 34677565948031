.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
  height: 65px;
}

.navbar_logo img {
  height: 52px;
  width: 52px;
}

.navLink_wrap {
  display: flex;
  list-style: none;
  align-items: center;
}

.nav_link {
  text-decoration: none;
  font-size: 15.6px;
  display: flex;
  align-items: center;
  padding-left: 1.3rem;
  @apply text-primary
}

.nav_link:hover, .nav_link.isActive{
    font-weight: 500;
    @apply transition ease-in-out duration-500
}

.hamburger_menu {
  width: 30px;
  height: 30px;
  display: none;
  cursor: pointer;
}

/* media */
@media (max-width: 768px) {
  .navLink_wrap {
    display: none;
  }

  .hamburger_menu {
    display: flex;
    align-items: center;
  }
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif;
}

:root {
  --primary: #800acc;
}

.myContainer {
  @apply container w-full mx-auto xl:px-28 md:px-6 lg:px-6 px-5;
}

/* BACK TO TOP */
.scroll-to-top {
  bottom: 13px;
  right: 0;
  width: 42px;
  height: 42px;
  z-index: 200;
  padding-top: 3px;
}

.scroll-to-top .icon2 {
  margin-top: 10px;
  color: wheat !important;
  @apply animate-bounce;
}

/* Testimonial component */
.testimonial {
  @apply bg-white py-4 px-2 text-center border-2 border-primary rounded-md mx-3 mb-5;
}

.testimonial p {
  @apply text-justify text-sm pb-2 tracking-wide;
}

/* ===General Banner ==== */
.banner {
  height: 45vh;
  width: 100%;
  background: url("./Images/banner.svg");
  background-position: center;
  background-size: cover;
}

/*=== Faq Css===*/

.accordion-wrapper {
  border: solid 1px #ccc;
  margin-bottom: 15px !important;
  padding: 0 10px;
}

.accordion-wrapper ul {
  list-style-type: square !important;
}

.accordion-content p {
  text-align: justify;
  padding: 2px 0;
  @apply text-sm;
}
.accordion-item {
  overflow: hidden;
  transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
  height: auto;
  max-height: 9999px;
}
.accordion-item.collapsed {
  max-height: 0;
  transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
}

.accordion-title::after {
  content: "";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
}

/* ABOUT PAGE */

.card {
  position: relative;
  height: 380px;
  box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.05),
    inset -5px -5px 5px rgba(255, 255, 255, 0.5),
    5px 5px 5px rgba(0, 0, 0, 0.05), -5px -5px 5px rgba(255, 255, 255, 0.5);
  border-radius: 15px;
}

.card .box {
  position: absolute;
  top: 20px;
  left: 20px;
  bottom: 20px;
  right: 20px;
  background: #ebf5fc;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
}

.card:hover .box {
  transform: translateY(-50px);
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2);
  background: linear-gradient(45deg, #b95ce4, #800acc);
}

.card .box .content {
  padding: 20px;
  text-align: center;
}

.card .box .content h2 {
  position: absolute;
  top: -10px;
  right: 30px;
  font-size: 8em;
  color: rgba(0, 0, 0, 0.05);
  transition: 0.5s;
  pointer-events: none;
}

.card:hover .box .content h2 {
  color: rgba(0, 0, 0, 0.05);
}

.card .box .content h3 {
  font-size: 1.2em;
  color: #777;
  z-index: 1;
  transition: 0.5s;
  padding-bottom: 10px;
  font-weight: 600;
}

.card .box .content p {
  font-weight: 300;
  color: #777;
  z-index: 1;
  transition: 0.5s;
  text-align: justify;
  @apply text-sm;
}

.card:hover .box .content h3,
.card:hover .box .content p {
  color: #ffff;
}

.card .box .content span {
  position: relative;
  display: inline-block;
  padding: 8px 20px;
  background: var(--primary);
  margin: 20px 0 0 0;
  border-radius: 20px;
  color: #fff;
  font-weight: 400;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.card:hover .box .content span {
  background: #ff568f;
}

/*== contact page ==*/
.form_input {
  @apply block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring;
}

/* general responsive modal */
.react-responsive-modal-modal {
  border-radius: 20px !important;
}

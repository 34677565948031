/* === Footer ====*/
.footer {
  background-color: #000;
  color: #fff;
}
.footer-wave-svg {
  background-color: transparent;
  display: block;
  height: 30px;
  position: relative;
  top: -1px;
  width: 100%;
}
.footer-wave-path {
  fill: #fffff2;
}

.footer-content-column h2 {
  @apply mb-4 font-medium;
}

.footer-content-column li {
  @apply text-sm my-1;
}

.contact-link {
  @apply flex items-center gap-3
}

.contact-link i {
  @apply text-primary text-base;
}

@media (min-width: 760px) {
  .footer-wave-svg {
    height: 50px;
  }
}

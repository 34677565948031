.sidedrawer {
    width: 78%;
    height: 100vh;
    background: #fff;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 500; 
    transform: translateX(-100%);
    transition: all 0.3s ease-out;
    display: flex;
    flex-direction: column;
    justify-content: center;
   
 }
 
 .sidedrawer.show {
 transform: translateX(0);
 }
 
 .sidedraw_links {
    display: flex;
    flex-direction: column;
    list-style: none; 
 }
 
 .sidedraw_links > li {
    display: flex;
    align-items: center;
 }
 
 .sidedraw_links > li > a {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    padding: 1rem;
    text-decoration: none;
    color: #000;
    font-size: 1rem;
 }
 
 .sidedraw_links > li > a:hover, .sidedraw_links > li > .is-active {
   @apply text-primary;
 }

 
 @media(min-width: 769px) {
    .sidedrawer {
       display: none;
    }
 }